import './Menu.scss';

export default function Menu(){
    return(
        <>
            <div className="floatingMenu">
                <a className='fmitem' href="./#">
                    <i class="bi bi-house"></i>
                </a>
                <a className='fmitem' href="#about">
                    <i class="bi bi-person"></i>
                </a>
                <a className='fmitem' href="#contact">
                    <i class="bi bi-envelope"></i>
                </a>
            </div>
        </>
    )
}

export function MobileMenu() {
    return(
        <>
            <div className="col-12 text-center mobileMenu">
                <div className="row">
                    <a className='col-4 fmitem' href="./#">
                        <i class="bi bi-house"></i>
                    </a>
                    <a className='col-4 fmitem' href="#about">
                        <i class="bi bi-person"></i>
                    </a>
                    <a className='col-4 fmitem' href="#contact">
                        <i class="bi bi-envelope"></i>
                    </a>
                </div>
            </div>
        </>
    )
}
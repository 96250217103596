export default function isdefault(value) {
    return null;
}

export function discord_message(name, email, message) {
    let messagetosend = `**Nombre:** ${name}\n**Correo Electrónico:** ${email}\n**Mensaje:**\n${message}`;
    let webhook = "https://discord.com/api/webhooks/993987138657984513/5XLqquPpd7_j7SuH7vVPDc1yfGji24xpZ5Zb43Bi_dRS7vUdUXALDxlpY5A7UW8JPGgU";
    const request = new XMLHttpRequest();
      request.open("POST", webhook);

      request.setRequestHeader('Content-type', 'application/json');

      const params = {
        username: "Message from Website",
        avatar_url: "https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/hrsuchi%2Flogo.png?alt=media&token=4cf2694b-930b-4541-9ce6-10d6dc7654b1",
        content: messagetosend
      }

      request.send(JSON.stringify(params));
}
import Header from "./Header";
import About from "./About";
import Contacto from "./Contacto";
import Menu, { MobileMenu } from "./Menu";
export default function Inicio() {
  return (
    <>
      <Menu />
      <Header />
      <About />
      <Contacto />
      <MobileMenu />
    </>
  );
}

import "./Header.scss";
import logo from "../../../logo.png";

export default function Header() {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="row cont-datos">
              <div className="datos text-center">
                <img src={logo} alt="logo" className="logo" />
                <h1>Hilmar Suchí</h1>
                <h2>
                  Desarrollador Web - Técnico IT - Estudiante de psicología
                  clínica
                </h2>
                <h2>
                  {" "}
                  <a href="mailto:hrsl492@gmail.com">hrsl492@gmail.com</a>{" "}
                </h2>
                <div className="line"></div>
                <a
                  className="sociallink"
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/hrsuchi"
                >
                  {" "}
                  <img
                    src="https://logodownload.org/wp-content/uploads/2019/08/github-logo-icon-0.png"
                    alt=""
                  />{" "}
                </a>
                <a className="morearrow" href="#about">
                  <i class="bi bi-caret-down-fill"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { discord_message } from "./functions";
import { useState } from "react";
export default function Contacto() {
    const [status, setStatus] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        setStatus('Enviando...');
        document.querySelector("#sbtn").disabled = true;
        discord_message(name, email, message);
        document.querySelector("#sbtn").disabled = false;
        setStatus('Enviado');
    }
    return (
        <form className="container" id="contact" onSubmit={handleSubmit}>
            <div className="row">
                <h1 className="text-center">Contacto</h1>
                <p className="text-center">Puede escribirme por medio del siguiente formulario de contacto, responderé a la brevedad posible.</p>
                <div className="mb-3">
                    <label for="name" className="form-label">Nombre:</label>
                    <input type="text" className="form-control" id="name" onChange={e => setName(e.target.value) } />
                </div>
                <div className="mb-3">
                    <label for="email" className="form-label">Correo Electrónico:</label>
                    <input type="email" className="form-control" id="email" onChange={e => setEmail(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label for="message" className="form-label">Mensaje:</label>
                    <textarea className="form-control" id="message" rows="3" onChange={e => setMessage(e.target.value)}></textarea>
                </div>
                <div className="mb-3">
                    <button className="btn btn-primary" id="sbtn" type="submit">Enviar</button> <p>{status}</p>
                </div>
            </div>
        </form>
    );
}
export default function Estudios() {
    return(
        <>
            <div className="container">
                <div className="row">
                    <h1>Estudios:</h1>
                    <div className="col-12 text-center">
                        <div className="estudio">
                            2022-Actualmente <br/>
                            <b>Licenciatura en Psicología Clínica</b> <br/>
                            <b>Universidad Galileo</b>
                        </div>
                        <div className="timeline"></div>
                        <div className="estudio">
                            2013 - 2014 <br/>
                            <b>Técnico en desarrollo de software</b> <br/>
                            <b>Universidad del valle de Guatemala</b>
                        </div>
                        <div className="timeline"></div>
                        <div className="estudio">
                            2012 <br/>
                            <b>Taller "Hablar en público"</b> <br/>
                            <b>Universidad San Carlos</b>
                        </div>
                        <div className="timeline"></div>
                        <div className="estudio">
                            2012 <br/>
                            <b>Taller "Relaciones humanas"</b> <br/>
                            <b>Universidad San Carlos</b>
                        </div>
                        <div className="timeline"></div>
                        <div className="estudio">
                            2010 - 2012 <br/>
                            <b>Bachillerato en computación</b> <br/>
                            <b>Instituto Emiliani Somascos</b>
                        </div>
                        <div className="timeline"></div>
                        <div className="estudio">
                            2009 <br/>
                            <b>Técnico en mantenimiento y reparacion de PC</b> <br/>
                            <b>INTECAP</b>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
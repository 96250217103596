export default function Desarrolloweb() {
    return (
        <>
            <div className="col-12 sectionsMargin">
                <div className="row">
                    <h2 className='text-center'>Desarrollo Web:</h2>
                    <ul className='col-12 col-md-6'>
                        <li>HTML5, Bootstrap</li>
                        <li>CSS3, SASS, LESS</li>
                        <li>Javascript, React, jQuery</li>
                        <li>PHP, WordPress, Laravel</li>
                    </ul>
                    <ul className='col-12 col-md-6'>
                        <li>SQL, MySQL, SQLite, SQL Server</li>
                        <li>Git, GitHub, Bitbucket</li>
                        <li>SSH, FTP, WebPanel</li>
                    </ul>
                </div>
            </div>
        </>
    )
}
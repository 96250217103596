export default function Soporteit() {
    return (
        <>
            <div className="col-12">
                <div className="row">
                    <h2 className='text-center'>Soporte IT:</h2>
                    <ul className='col-12 col-md-6'>
                        <li>Mantenimiento de PC</li>
                        <li>Soporte técnico</li>
                        <li>Soporte remoto</li>
                    </ul>
                    <ul className='col-12 col-md-6'>
                        <li>Instalación de sistemas operativos</li>
                        <li>Instalación de programas</li>
                        <li>Instalación de aplicaciones móviles</li>
                    </ul>
                </div>
            </div>
        </>
    )
}
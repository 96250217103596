import './About.scss';
import Desarrolloweb from './Desarrolloweb';
import Soporteit from './Soporteit';
import Estudios from './Estudios';

export default function About() {
    return (
        <>
            <div className="container-fluid text-center about sectionsMargin" id='about'>
                <div className="col-12 sectionsMargin">
                    <h1>Sobre mi</h1>
                    <div className="container">
                        <p>
                            Soy técnico en desarrollo web.<br/>
                            Tengo mas de 8 años de experiencia en desarrollo de aplicaciones web tanto en la parte frontend como backend. <br/>
                            He trabajado en agencias de publicidad así como también en empresas que tienen su propio equipo de desarrollo. <br />
                            Actualmente me encuentro estudiando una licenciatura en psicología clínica, lo cual decidí estudiar para complementar
                            con mis habilidades en informática para así poder entender mejor las necesidades las personas.
                        </p>
                    </div>
                    <div className="container">
                        <div className="bottomline"></div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 sectionsMargin">
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <b>Fecha de Nacimiento:</b> 19 de febrero de 1992
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <b>Nacionalidad:</b> Guatemalteco
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <b>Estado Civil:</b> Soltero
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 sectionsMargin">
                                    <h2>Objetivo:</h2>
                                    <p>
                                        Desarrollar e implementar herramientas útiles para cualquier área y que faciliten la vida y trabajos de las personas.
                                    </p>
                                </div>
                                <div className="col-12 sectionsMargin">
                                    <h2>HABILIDADES</h2>
                                </div>
                                <div className="col-12 col-md-6 divideline">
                                    <Desarrolloweb />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Soporteit />
                                </div>
                                <div className="col-12">
                                    <Estudios />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}